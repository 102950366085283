import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BootstrapperBase, ExportToken } from '@trustedshops/tswp-core-common';
import { FeatureBookingConfig, FeatureBookingService, TOKENS as MASTERDATA_TOKENS } from '@trustedshops/tswp-core-masterdata';
import { ENVIRONMENT, Environment } from '../models/environment.type';

/**
 * Overwrite the default implementation of the FeatureBookingService
 * FeatureBooking API is not available for employees
 * All methods return default values to continue the application flow
 */
@Injectable()
@ExportToken(MASTERDATA_TOKENS.FeatureBookingService)
export class FeatureBookingServiceEmployee implements FeatureBookingService {
  private static readonly TYPE: string = 'FeatureBookingService';

  initialize(config: FeatureBookingConfig): Promise<void> {
    return Promise.resolve(void 0);
  }
  hasAccountFeatureBooked(featureName: string): Promise<boolean> {
    return Promise.resolve(true);
  }
  hasChannelFeatureBooked(channelRef: string, featureName: string): Promise<boolean> {
    return Promise.resolve(true);
  }
  isFreeAccount(): Promise<boolean> {
    return Promise.resolve(false);
  }
  isUnverifiedAccount(): Promise<boolean> {
    return Promise.resolve(false);
  }
  isPaidAccount(): Promise<boolean> {
    return Promise.resolve(false);
  }
}


@Injectable()
export class FeatureBookingBootstrapper implements BootstrapperBase {
  public constructor(
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService) private readonly _featureBookingService: FeatureBookingService,
  ) {
  }
  public async initialize(): Promise<void> {
    // FeatureBooking API is not available for employees
    await this._featureBookingService.initialize({
      basePath: ''
    });
  }
}


export const FeatureBookingBootstrapperInitializer = [
  {
    provide: MASTERDATA_TOKENS.FeatureBookingService,
    useClass: FeatureBookingServiceEmployee
  },
  FeatureBookingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => () => initializer.initialize(),
    deps: [FeatureBookingBootstrapper,
    ]
  }
];
