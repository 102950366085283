import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PluginsApiConfigurationModule } from './plugins-api-configuration.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PluginResolverServiceImpl } from '../services/plugin-resolver.service';
import { TswpCarrierDefaultConfigModule } from '@trustedshops/tswp-carrier-core';
import { CultureInfoDeDE, CultureInfoEnGB, TOKENS, TranslationFile } from '@trustedshops/tswp-core-common';
import { FeatureBookingBootstrapperInitializer } from './feature-booking.bootstrapper';
import { environment } from 'projects/tswp-carrier-employees/environments/environment';
import { PageTitleBootstrapperInitializer } from './page-title.bootstrapper';
import { TrackingConfiguration } from './tracking.configuration';
import { Environment } from '../models/environment.type';



declare let platformConfiguration: Environment;

Object.assign(environment, platformConfiguration);

@NgModule({
  imports: [
    //#region Trusted Shops Web Platform
    TswpCarrierDefaultConfigModule.withConfig({
      plugins: {
        resolver: PluginResolverServiceImpl,
        storageRootPath: environment.storage.plugins.rootPath
      },
      globalization: {
        cultures: [
          CultureInfoEnGB,
          CultureInfoDeDE
        ],
        defaultCulture: CultureInfoEnGB
      },
      diagnostics: {
        logging: {
          logLevels: environment.diagnostics.logging.logLevels,
          filters: environment.diagnostics.logging.filters,
          sentry: environment.diagnostics.logging.sentry,
          instana: environment.diagnostics.logging.instana
        }
      },
      environment: {
        friendlyName: 'Trusted Shops Employees Platform',
        id: 'tswp-employees',
        targetUserGroup: environment.targetGroup,
        stage: {
          name: environment.name,
          productionModeEnabled: environment.production
        }
      },
      authorization: {
        sessionProviderConfig: {
          url: environment.authorization.keycloakUrl,
          clientId: environment.authorization.clientId,
          realm: environment.authorization.realm,
          sessionVerificationType: environment.authorization.sessionVerificationType,
          silentCheckSsoRedirectUri: environment.authorization.silentCheckSsoRedirectUri,
          initializationOptions: {
            loadUserProfile: false,
            loadUserInfo: false
          }
        },
        protectedEndpoints: [
          environment.apis.plugins
        ]
      }
    }),
    //#endregion

    //#region Trusted Shops Web Plaform Apis
    PluginsApiConfigurationModule,
    //#endregion

    //#region @angular
    HttpClientModule,
    BrowserAnimationsModule,
    //#endregion

    //#region Material
    MatTooltipModule,
    //#endregion
  ],
  providers: [
    {
      provide: TOKENS.DefaultTranslationServiceConfig,
      useValue: {
        translationKeyResolver: (translations: TranslationFile, key: string) => translations[key],
        localeFileNameResolver: (locale: string) => `assets/i18n/${locale}.json`
      }
    },
    FeatureBookingBootstrapperInitializer,
    PageTitleBootstrapperInitializer,
    TrackingConfiguration,
  ],
  exports: [
    //#region Trusted Shops Web Platform
    TswpCarrierDefaultConfigModule,
    //#endregion

    //#region @angular
    HttpClientModule,
    BrowserAnimationsModule,
    //#endregion

    //#region Material
    MatTooltipModule,
    //#endregion
  ]
})
export class SharedModule {

}
