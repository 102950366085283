import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule } from '@angular/router';
import { LogLevel } from '@trustedshops/tswp-core-common';
import { TOKENS } from '@trustedshops/tswp-core-masterdata';
import { TOKENS as TOKENS_UI } from '@trustedshops/tswp-core-ui';
import { environment } from '../environments/environment';
import { SharedModule } from './configuration/shared.module';
import { HeaderBarComponent } from './controls/header-bar/header-bar.component';
import { SidebarComponent } from './controls/sidebar/sidebar.component';
import { TertiaryMenuBarComponentInitializer } from './controls/tertiary-menu-bar/tertiary-menu-bar.bootstrapper';
import { TertiaryMenuBarComponent } from './controls/tertiary-menu-bar/tertiary-menu-bar.component';
import { ENVIRONMENT } from './models/environment.type';
import { DefaultOrganizationalContainerSource } from './services/default-organizational-container.source';
import { PluginResolverServiceImpl } from './services/plugin-resolver.service';
import { PresentationComponent } from './views/presentation/presentation.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    PresentationComponent,
    HeaderBarComponent,
    SidebarComponent,
    TertiaryMenuBarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
    ],
      { enableTracing: (environment?.diagnostics?.logging?.logLevels || []).includes(LogLevel.Trace) }
    ),
    SharedModule,
  ],
  exports: [
    BrowserModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    TertiaryMenuBarComponentInitializer,
    PluginResolverServiceImpl,
    { provide: ENVIRONMENT, useFactory: () => ({...environment})},
    {
      provide: TOKENS.DefaultOrganizationalContainerSource,
      useFactory: source => source,
      deps: ['null']
    },
    {
      provide: 'null',
      useClass: DefaultOrganizationalContainerSource
    },
    {
      provide: TOKENS.DefaultOrganizationalContainerRenderer,
      useValue: {
        webComponent: 'div',
        plugin: null
      }
    },
    {
      provide: TOKENS_UI.UpgradePopupService,
      useValue: {}, // No upgrade popup for employees
    }
  ]
})
export class AppModule { }
